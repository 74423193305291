import React, { Component } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

class PrivacyPolicy extends Component {
  render() {
    return (
      <Layout>
        <SEO title="Welcome to InTow Connect" />
        <section className="section   ptb-60">
          <div className="container text-center p-4">
            <div className="row mb-40">
              <h1 className="text-left col-md-12 col-sm-12">Privacy Policy</h1>
              <div className="col-lg-12 col-md-12 col-sm-10 col-xs-12 col-xs-center text-left">
                <h5 className="pt-20">DENTED Development Privacy Policy</h5>
                <p className="text-left ">
                  This Privacy Policy describes how your personal information is
                  collected, used, and shared when you visit
                  <a href="https://www.intowconnect.com" target="_balnk">
                    {" "}
                    intowconnect.com
                  </a>{" "}
                  or the InTow Connect mobile app.
                </p>
                <h5 className="pt-20">PERSONAL INFORMATION WE COLLECT</h5>
                <p className="text-left ">
                  When you visit the Site, we automatically collect certain
                  information about your device, including information about
                  your web browser, IP address, time zone, and some of the
                  cookies that are installed on your device. Additionally, as
                  you browse the Site, we collect information about the
                  individual web pages or products that you view, what websites
                  or search terms referred you to the Site, and information
                  about how you interact with the Site. We refer to this
                  automatically-collected information as “Device Information.”
                </p>
                <h5 className="pt-20">
                  We collect Device Information using the following
                  technologies:
                </h5>
                <h6 className="pt-20">WEBSITE</h6>
                <p className="text-left ">
                  - “Cookies” are data files that are placed on your device or
                  computer and often include an anonymous unique identifier. For
                  more information about cookies, and how to disable cookies,
                  visit http://www.allaboutcookies.org. - “Log files” track
                  actions occurring on the Site, and collect data including your
                  IP address, browser type, Internet service provider,
                  referring/exit pages, and date/time stamps. - “Web beacons,”
                  “tags,” and “pixels” are electronic files used to record
                  information about how you browse the Site.
                </p>
                <h6 className="pt-20">MOBILE APP</h6>
                <p className="text-left ">
                  - "Background Geolocation data": When a tow truck company has assigned a driver to tow a car, the dispatcher needs to be able to monitor the driver's progress in order to know if they are on schedule, and when they are available to respond to others calls. As such, the app needs to periodically send its geolocation to the towing company. We need to include background location tracking so that the driver does not need to have the app open the entire time they are driving.
                </p>
                <p className="text-left ">
                 <strong>When we talk about “Personal Information” in this Privacy Policy, we are talking both about Device Information and Order Information.</strong>
                </p>
                <h5 className="pt-20">CONTACT US</h5>
                <p className="text-left">For more information about our privacy practices, if you have questions, or if you would like to make a complaint, please contact us by e-mail at <a href="mailto:support@denteddev.com">support@denteddev.com</a> or by mail using the details provided below:
                </p>
                 <p className="text-center"><strong>837 Sunninghill Avenue London, Ontario, Canada N6H 3M1, London, ON, N6H 3M1, Canada</strong></p>
              </div>
            </div>
            <div className="row mb-20 privacy-btn text-center pt-20">
              <div className="p-20 col-md-12">
                <a
                  className="text-center privacyToHome"
                  href="/"
                >
                  Back to HomePage
                </a>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    )
  }
}

export default PrivacyPolicy
